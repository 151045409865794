<template>
  <div>
    <div
      v-for="(item,index) in faqs"
      :key="index"
      class="relative border-b border-[#454545] py-8"
    >
      <input
        :id="'toggle'+index"
        type="checkbox"
        class="peer hidden"
      >
      <label
        class="title dark:text-white ttext-base sm:text-lg font-semibold block cursor-pointer pr-10 before:absolute before:right-5 before:top-9 before:w-[2px] before:h-3 before:bg-black dark:before:bg-white before:transition-all after:absolute after:right-5 after:top-9 after:w-[2px] after:h-3 after:bg-black dark:after:bg-white after:transition-all after:rotate-90 peer-checked:before:rotate-90"
        :for="'toggle'+index"
      >
        {{ item.question }}
      </label>
      <div class="peer-checked:max-h-none max-h-0 overflow-hidden">
        <p class="pt-2 text-grey text-sm">
          {{ item.answer }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const faqs = [
  {
    'question': 'Do I need an Airtable Pro account to use AirFormTable?',
    'answer': 'No, you do not need an Airtable Pro account to use AirFormTable. Our tool is designed to work\n' +
      '                  seamlessly with all Airtable account types, including the free plan.'
  },
  {
    'question': 'Can I customize the design of my forms?',
    'answer': 'Absolutely! With AirFormTable, you can fully customize the design of your forms to match your brand. Our advanced customization options allow you to modify colors, themes, logos, and covers.'
  },
  {
    'question': 'Can I edit existing records through the forms?',
    'answer': 'Yes, AirFormTable allows you to enable form users to see and update their previous submissions. Additionally, forms can be pre-filled with existing records from your Airtable base, making it easier for users to edit and resubmit information.'
  },
  {
    'question': 'What integrations are available with AirFormTable?',
    'answer': 'AirFormTable supports multiple integrations to streamline your workflow. You can receive notifications via email and Slack, and use Webhooks to create automations with your form submissions. More integrations are continuously being added to enhance functionality.'
  }
]
</script>
