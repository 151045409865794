<template>
  <div id="pricing">
    <div class="text-center mx-auto mb-10">
      <h3
        class="my-4 text-3xl md:text-4xl lg:text-[4rem] font-bold md:!leading-[1.2] tracking-[-2.56px]"
      >
        A transparent pricing
      </h3>
      <p class="text-lg px-3 text-grey font-medium">
        Start with <span class="font-semibold">one form for free,</span>. Upgrade as you grow.
      </p>
    </div>
    <div class="flex items-center justify-center">
      <monthly-yearly-selector
        v-model="isYearly"
        class="my-5"
      />
    </div>

    <div class="sm:p-4 flex justify-center">
      <div
        class="w-full md:w-1/2 lg:w-full max-w-sm p-2"
      >
        <div
          class="relative flex flex-col h-full p-8 transition duration-500 ease-in-out transform border rounded-lg shadow-xl bg-gradient-to-r from-blue-400 to-blue-600"
        >
          <h2 class="mb-4 text-black">
            <span class="text-white">Pro</span>
          </h2>
          <p
            class="flex items-center mb-8 text-base font-medium leading-relaxed text-white"
          >
            Unlimited forms. Remove branding. Full customization.
          </p>
          <div class="flex items-center justify-center text-white">
            <strong
              v-if="!isYearly"
              key="monthly"
              class="flex items-end text-3xl font-black leading-none lg:text-4xl"
            >
              $39 <span class="text-sm"> /month </span>
            </strong>
            <strong
              v-else
              key="yearly"
              class="flex items-end text-3xl font-black leading-none lg:text-4xl"
            >
              $33
              <span class="text-sm"> /month </span>
            </strong>
          </div>
          <p
            class="flex items-center mt-8 mb-2 text-base font-medium leading-relaxed text-white"
          >
            <span
              class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 rounded-full text-blue-500 bg-white"
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
                class="w-3 h-3"
                viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5" />
              </svg> </span>Everything in free plan
          </p>
          <p
            class="flex items-center mb-2 text-base font-medium leading-relaxed text-white"
          >
            <span
              class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 rounded-full text-blue-500 bg-white"
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
                class="w-3 h-3"
                viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5" />
              </svg> </span>Full form customization
          </p>
          <p
            class="flex items-center mb-2 text-base font-medium leading-relaxed text-white"
          >
            <span
              class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 rounded-full text-blue-500 bg-white"
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
                class="w-3 h-3"
                viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5" />
              </svg> </span>Remove AirFormTable Branding
          </p>
          <p
            class="flex items-center mb-2 text-base font-medium leading-relaxed text-white"
          >
            <span
              class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 rounded-full text-blue-500 bg-white"
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
                class="w-3 h-3"
                viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5" />
              </svg>
            </span>
            Multiple Integrations
          </p>
          <p
            class="flex items-center mb-2 text-base font-medium leading-relaxed text-white"
          >
            <span
              class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 rounded-full text-blue-500 bg-white"
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
                class="w-3 h-3"
                viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5" />
              </svg> </span>20 mb file uploads
          </p>
          <v-button
            v-if="!authenticated || !user.is_subscribed"
            color="white"
            font-weight="semibold"
            class="w-full mt-4"
            @click.prevent="onSelectPlan('default')"
          >
            Get started
          </v-button>
          <v-button
            v-else-if="authenticated && !user.has_enterprise_subscription"
            disabled
            color="transparent"
            class="mt-4 border border-white w-full font-medium text-sm"
          >
            Your current plan
          </v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import MonthlyYearlySelector from "../pricing/MonthlyYearlySelector.vue"

export default {
  components: { MonthlyYearlySelector },
  props: {},
  setup() {
    const subscriptionModalStore = useSubscriptionModalStore()
    const authStore = useAuthStore()
    return {
      subscriptionModalStore,
      authenticated: computed(() => authStore.check),
      user: computed(() => authStore.user),
    }
  },
  data: () => ({
    isYearly: true,
  }),

  computed: {},

  methods: {
    onSelectPlan (planName) {
      if (this.authenticated) {
        this.subscriptionModalStore.openModal(planName, this.isYearly)
        return
      }
      const router = useRouter()
      router.push({ name: 'register' })
    }
  },
}
</script>
